import { Bank } from './model'
import { getJson, HttpClient, SessionData } from '../core'

export class BanksClient {
  constructor(private readonly httpClient: HttpClient) {}

  getAll(sessionData: SessionData): Promise<Bank[]> {
    return getJson<Bank[]>(this.httpClient, ['api', 'banks'], sessionData)
  }

  getActive(sessionData: SessionData): Promise<Bank[]> {
    return getJson<Bank[]>(this.httpClient, ['api', 'banks'], sessionData).then(bs => bs.filter(b => b.isActive))
  }
}
